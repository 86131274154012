@import "./variable.scss";

.bat_modals_active {
  visibility: visible !important;
}
.bat_modals_container {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(252, 252, 252, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
  top: 0;
}

.bat_modals_title {
  h5 {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px !important;
  }
  p {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 15px !important;
  }
}

.bat_modals_authentificating {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 600px;
  height: 200px;
  background-color: $bkg;
  position: absolute;
  border-radius: 8px;
  margin: auto;

  .bat_modals_title {
    margin-top: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
    }
  }
  @media (min-width: 1600px) {
    .bat_modals_titlecontent {
      margin-bottom: 15px !important;
      margin-top: 0px !important;
    }
  }

  .bat_modal_margin2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto !important;
    .bat_modals_title {
      margin-top: 0px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      h5 {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .bat_modals_import {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
    }
  }
}
.bat_modals {
  width: 600px;
  background-color: $bkg;
  position: absolute;
  border-radius: 8px;
  margin: auto;
}
.bat_modal_margin {
  margin: auto !important;
  margin-top: 40px !important;
  margin-bottom: 30px !important;
}
.creation_import_preview {
  height: 25%;
}
.bat_modals_close {
  position: absolute;
  top: -20px;
  right: -5px;
  .bat_modals_closebtn {
    background-image: url("../img/close.svg");
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    height: 32px;
    width: 32px;
    background-color: transparent;
    border: none;
    outline: none;
  }
}
.textarea {
  .imprimeur_id {
    color: $blue;
    font-size: 14px;
  }
  p {
    font-size: 18px;
  }
  textarea {
    width: 100%;
    background-color: #f4f5f9;
    border: 1px solid #d0d1d5;
    border-radius: 8px;
    outline: none;
    resize: none;
  }
  input {
    width: 100%;
    background-color: #f4f5f9;
    border: 1px solid #d0d1d5;
    border-radius: 8px;
    outline: none;
    resize: none;
    margin-bottom: 10px;
    .imprimeur_email {
      background-color: #f4f5f9;
      border: 1px solid #e1e1e2;
      border-radius: 100px;
      outline: none;
      resize: none;
      margin-bottom: 10px;
    }
  }
}
.creation_import_search_text {
  p {
    font-size: 13px;
    line-height: 20px;
    padding-right: 20px;
  }
}
.creation_import_choose {
  margin-bottom: 10px !important;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Importantbtn {
  border: none;
  background-color: $blue;
  border-radius: 8px;
  height: 40px;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  display: flex;
  position: absolute;
  cursor: pointer;
  color: white;
}
.Importantbtn:disabled,
.Importantbtn[disabled] {
  background-color: #d5d5db;
}

.creation_import_search {
  margin-bottom: 15px !important;
  a {
    color: $blue;
    text-decoration: underline;
  }
}

.link {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  button {
    border: none;
    padding-right: 12px;
    padding-left: 12px;
    background-color: #f4f5f9;
    border-right: 1px solid #d0d1d5;
  }
  p {
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    font-size: 14px;
  }
}
.password {
  margin-top: 10px !important;
  display: flex;
  justify-content: space-between;
}
.password_number {
  background-color: #f4f5f9;
  border: 1px solid #d0d1d5;
  border-radius: 8px;
  width: 100px;
  height: 30px;

  p {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 500;
  }
}

//APPROVE MODAL
.ApprovebtnDIV {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.Approvebtn {
  border: none;
  background-color: #2cb08a;
  border-radius: 8px;
  height: 40px;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  display: flex;
  cursor: pointer;
  color: white;
  width: 70%;
}
.Approvebtn:active {
  background-color: #2aa885;
}
.Approvebtn:disabled,
.Approvebtn[disabled] {
  background-color: #d5d5db;
}

.Atextarea {
  padding: 0;
  margin: auto;
  margin-top: 10px;
  .imprimeur_id {
    color: $blue;
    font-size: 14px;
  }
  p {
    font-size: 18px;
  }
  .textarea {
    padding: 0px !important;
  }
  textarea {
    width: 100%;
    background-color: #f4f5f9;
    border: 1px solid #d0d1d5;
    border-radius: 8px;
    outline: none;
    resize: none;
    padding: 5px;
    margin-top: 10px;
  }
  input {
    width: 100%;
    background-color: #f4f5f9;
    border: 1px solid #d0d1d5;
    border-radius: 8px;
    outline: none;
    resize: none;
    margin-bottom: 10px;
  }
  .imprimeur_email {
    background-color: #f4f5f9;
    border: 1px solid #7e7e7e;
    border-radius: 100px;
    outline: none;
    resize: none;
    margin-bottom: 10px;
  }
}

.Acreation_import_search_text {
  height: 70px;
  width: 100%;
  p {
    font-size: 13px;
    line-height: 20px;
    padding-right: 20px;
  }
}
.AAtextarea {
  padding: 0px !important;
}

@media (min-width: 1600px) {
  .bat_modals {
    width: 900px;
    height: 680px;
    background-color: $bkg;
    position: absolute;
    border-radius: 8px;
    margin: auto;
  }
}
@media (min-width: 1600px) {
  .bat_modals_title {
    h5 {
      margin-bottom: 40px !important;
      margin-top: 60px !important;
    }
  }
  .textarea {
    input {
      margin-bottom: 10px !important;
      height: 32px;
      font-size: 15px;
      .imprimeur_email {
        margin-bottom: 15px !important;
      }
    }
    textarea {
      margin-bottom: 20px;
      height: 270px;
      font-size: 15px;
    }
  }
  .creation_import_search_text {
    p {
      font-size: 15px;
    }
  }
  .Importantbtn {
    font-size: 16px;
  }
  .link {
    height: 30px;
    display: flex;
    align-items: center;
  }
  .creation_import_choose {
    margin-bottom: 20px !important;
  }
  .creation_import_search {
    margin-bottom: 40px !important;
  }
  .bat_modals_title {
    p {
      font-size: 18px !important;
    }
  }
}
@media (max-width: 1600px) {
  .creation_import_search {
    margin-bottom: 30px !important;
  }
}

//APPROUVE
@media (min-width: 1600px) {
  .Atextarea {
    margin-top: 15px !important;
    .imprimeur_id {
      font-size: 18px !important;
    }
    .textarea {
      padding: 0px !important;
    }
    textarea {
      height: 270px !important;
    }
    .imprimeur_email {
      margin-bottom: 15px !important;
      height: 30px;
    }
  }
  .Acreation_import_search_text {
    height: 90px !important;
    p {
      font-size: 15px !important;
    }
  }
  .ApprovebtnDIV {
    button {
      font-size: 17px;
      font-weight: 500;
    }
  }
}
.inputzone2 {
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 120px);
  .inputzone2zone {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100%);
  }
  .sharelink {
    background-color: hsla(0, 0%, 98.8%, 0.336);
    border: 1px solid #fafafa;
    width: 100%;
    border-radius: 8px;
    height: 30px;
    font-size: calc(12px + 0.21vw);
    padding-left: 10px;
    display: flex;
    align-items: center;
    // padding-right: 92px;
    white-space: nowrap;
    overflow: scroll;
    transition: 0.1s;
    padding-right: 191px;
  }
  .ajouter {
    position: absolute;
    right: 93px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-color: var(--blue);
    transition: 0.1s;
    width: 91px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      padding: 3px 15px;
      color: #fff;
      -webkit-user-select: none;
      user-select: none;
      font-size: 13px !important;
    }
  }
  .envoyer {
    position: absolute;
    right: 0px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-color: var(--blue);
    transition: 0.1s;
    width: 91px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      padding: 3px 7px;
      color: #fff;
      -webkit-user-select: none;
      user-select: none;
      font-size: 13px !important;
    }
    a {
      padding: 3px 7px;
      color: #fff;
      -webkit-user-select: none;
      user-select: none;
      font-size: 13px !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      svg {
        width: 15px;
        margin-left: 7px !important;
      }
    }
  }
  .gray {
    background-color: #4d5264 !important;
  }
}
.qrcode {
  width: 120px;

  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  .qrcodezone {
    justify-content: center;
    flex-wrap: wrap;
    width: auto;
    align-items: flex-end;
    align-content: flex-end;
    p {
      margin: 0;
      padding: 0;
      width: auto;
      font-size: 14px;
      height: auto;
      margin-bottom: 5px !important;
      text-align: center;
      font-family: "Poppins";
      text-align: center;
      font-weight: 500;
      @media (min-width: 1600px) {
        font-size: 18px !important;
      }
    }

    svg {
      width: 65px;
      height: 65px;

      margin: auto;
    }
  }
}

@import "./home.module.scss";

.container {
  margin-top: 60px;
  flex-wrap: wrap;
  overflow: scroll;
  background-color: var(--bkgconnect);
  height: calc(100vh - 60px);

  align-content: flex-start;

  display: flex;

  padding: 0 30px;
  flex-wrap: wrap;
  overflow: hidden;

  align-content: flex-start;
  position: relative;


}

.addpn {
  background-color: var(--colorsec);
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: calc(10px + 2vh);
  margin-bottom: calc(10px + 0.7vh);
  .titlehelp {
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
      font-family: "worksans";
      font-size: var(--verylittlefont);
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
    div {
      width: var(--medfont);
      height: var(--medfont);
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .contentpn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    .choosepn {
      width: calc(100%);
      background-color: var(--inputcolor);
      outline: none;
      border: 1px solid var(--inputborder);
      font-family: "worksans";
      font-size: var(--littlefont);
      font-weight: 400;
      padding: 0;
      color: var(--B_W);
      height: calc(var(--littlefont) + 20px);

      background-color: var(--inputcolor);
      outline: none;
      box-shadow: none;
      .react-select__control {
        background-color: var(--inputcolor);
        color: white;

        outline: none;
      }
      div {
        box-shadow: none;
        input::placeholder {
          outline: none;
          color: white;
        }
      }
    }

    // input {
    //   width: calc(100% - 150px);
    //   background-color: var(--inputcolor);
    //   outline: none;
    //   border: 1px solid var(--inputborder);
    //   font-family: "worksans";
    //   font-size: var(--littlefont);
    //   font-weight: 400;
    //   padding-top: 10px;
    //   padding-bottom: 10px;
    //   padding-right: 15px;
    //   padding-left: 15px;
    //   color: var(--B_W);
    //   height: calc(var(--littlefont) + 20px);
    // }
    .search {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--W_B);
      border: 1px solid var(--B_W);
      height: max-content;
      cursor: pointer;
      height: calc(var(--littlefont) + 18px);
      transition: 0.1s;
      p {
        margin: 0;
        padding: 0;
        font-family: "worksans";
        font-size: var(--verylittlefont);
        font-weight: 400;
      }
    }
    .search:hover {
      background-color: var(--buttonhover);
    }
  }
}

.infos {
  background-color: var(--colorsec);
  margin: 0;
  padding: 0;
  margin-top: calc(10px + 0.7vh);
  margin-bottom: calc(10px + 0.7vh);
  .titlesection {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: var(--inputcolor);
    p {
      font-family: "worksans";
      font-size: var(--verylittlefont);
      font-weight: 400;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }
    div {
      width: calc(var(--medfont) + 5px);
      height: calc(var(--medfont) + 5px);
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .infoscontent {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    .oneinfo {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding-top: calc(7px + 0.3vh);
      padding-bottom: calc(7px + 0.3vh);
      .text {
        margin: 0;
        padding: 0;
        p {
          font-family: "worksans";
          font-size: var(--verylittlefont);
          font-weight: 400;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
        }
      }
      .input {
        min-width: calc(5% + 220px);

        margin: 0;
        padding: 0;
        input {
          width: calc(100%);
          background-color: var(--inputcolor);
          outline: none;
          border: 1px solid var(--inputborder);
          font-family: "worksans";
          font-size: var(--verylittlefont);
          font-weight: 400;
          padding-top: 7px;
          padding-bottom: 7px;
          padding-right: 15px;
          padding-left: 15px;
          margin: 0;
          color: var(--B_W);
          height: calc(var(--verylittlefont) + 14px);
        }
      }
      .checkbox [type="checkbox"]:checked {
        background-image: url(../img/icons/check.svg);
        background-size: 95%;
        filter: invert(1);
        background-repeat: no-repeat;
        background-position: center;
        border: 2px solid #444444;
        padding: 0;
      }
    }
  }
}
.number {
  input {
    border: 1px solid rgba(255, 255, 255, 0.757) !important;
  }
}
.nbproduit {
  background-color: var(--colorsec);
  margin: 0;
  padding: 0;
  margin-top: calc(10px + 0.7vh);
  margin-bottom: calc(10px + 2vh);
  .titlesection {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: var(--inputcolor);
    p {
      font-family: "worksans";
      font-size: var(--verylittlefont);
      font-weight: 400;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }
    div {
      width: calc(var(--medfont) + 5px);
      height: calc(var(--medfont) + 5px);
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .nbproduitzone {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    .text {
      margin: 0;
      padding: 0;
      p {
        font-family: "worksans";
        font-size: var(--verylittlefont);
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
    }
    .input {
      margin: 0;
      padding: 0;
      input {
        width: calc(30);
        background-color: var(--inputcolor);
        outline: none;
        border: 1px solid var(--inputborder);
        font-family: "worksans";
        font-size: var(--verylittlefont);
        font-weight: 400;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-right: 15px;
        padding-left: 15px;
        margin: 0;
        color: var(--B_W);
        height: calc(var(--verylittlefont) + 14px);
      }
    }
  }
}

.viewlabel {
  background-color: var(--colorsec);
  margin: 0;
  padding: 0;

  padding-bottom: 10px;

  margin-top: calc(10px + 2vh);
  margin-bottom: calc(10px + 2vh);
  .titlesection {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: var(--inputcolor);
    p {
      font-family: "worksans";
      font-size: var(--verylittlefont);
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
    div {
      width: var(--medfont);
      height: var(--medfont);
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .etiquettepreviewzone {
    margin: 0;
    padding: 0;
    height: calc(100% - 105px);
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.generate {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--W_B);
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.555);
  p {
    margin: 0;
    padding: 0;
  }
  img {
    width: 17px;
    margin-right: 13px;
  }
}
.generate:hover {
  background-color: rgb(27, 27, 27);
}
.generate:active {
  background-color: var(--buttonhover);
}

.disabled {
  opacity: 0.25;
  cursor: default;
}

.disabled:hover {
  background-color: var(--W_B);
}

.disabled:active {
  background-color: var(--W_B);
}

.dateText {
  margin-left: 1rem !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-family: "worksans";
  font-size: var(--verylittlefont);
  font-weight: 400;
  margin: 0;
  color: var(--B_W);
  height: calc(var(--verylittlefont) + 14px);
}

.inputDate::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

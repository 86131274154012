.container {
  margin-top: 60px;
  flex-wrap: wrap;
  overflow: scroll;
  background-color: var(--bkgconnect);
  height: calc(100vh - 60px);

  align-content: flex-start;

  display: flex;

  padding: 0 30px;
  flex-wrap: wrap;
  overflow: hidden;

  align-content: flex-start;
  position: relative;


}

.table_container {
  padding: 10px;
  width: 100%;
  height: calc(100% - 60px);
}

.export {
  width: 100%;
  animation-duration: 0.3s;
  animation-name: allprojects_agrandirN__2qi4g;
  display: flex;
  padding: 0px 15px;

  button {
    display: flex;
    height: calc(30px);
    justify-content: center;
    align-items: center;
    width: auto;
    background: black;

    box-shadow: none;
    border: none;
    margin-left: 0;
    border-radius: 1px;
  }
  button:hover {
    background-color: #262a69;
  }
}

.in_button {
  padding: 0px !important;
  align-items: center;
  display: flex;
  font-size: var(--verylittlefont) !important;
  padding: 0 8px 0 8px !important;
  font-weight: 400;
  color: var(--B_W);
  margin: 0;
  font-family: "worksans";
}

@import "./variable.scss";

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: #000000;
// }

::-webkit-scrollbar {
  display: none;
}

// ::-webkit-scrollbar-thumb {
//   background-color: #383838;
// }

// -------------- FONTS --------------

@font-face {
  font-family: "WorkSans";
  font-weight: 900;
  src: local("WorkSans"),
    url(../fonts/WorkSans/WorkSans-Black.ttf) format("truetype");
}

@font-face {
  font-family: "WorkSans";
  font-weight: 800;
  src: local("WorkSans"),
    url(../fonts/WorkSans/WorkSans-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "WorkSans";
  font-weight: 700;
  src: local("WorkSans"),
    url(../fonts/WorkSans/WorkSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "WorkSans";
  font-weight: 600;
  src: local("WorkSans"),
    url(../fonts/WorkSans/WorkSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "WorkSans";
  font-weight: 500;
  src: local("WorkSans"),
    url(../fonts/WorkSans/WorkSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "WorkSans";
  font-weight: 400;
  src: local("WorkSans"),
    url(../fonts/WorkSans/WorkSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "WorkSans";
  font-weight: 300;
  src: local("WorkSans"),
    url(../fonts/WorkSans/WorkSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "WorkSans";
  font-weight: 100;
  src: local("WorkSans"),
    url(../fonts/WorkSans/WorkSans-Thin.ttf) format("truetype");
}

// -------------- FONTS END --------------

html {
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: black;
}

body {
  height: 100%;
  width: 100%;
  color: var(--B_W);
}

.home {
  height: 100%;
  width: 100%;
  padding: 0;
  position: absolute;
  overflow: none;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: var(--bkgconnect);
  color: white;
  transition: 0.5s;
  scroll-behavior: smooth;

  //filter: invert(1);//

  .div {

  }

  section {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
  }

  strong {
    text-transform: uppercase;
    font-weight: 500;
  }
  p {
    font-family: "Worksans";
    font-size: 1.1rem;
    font-weight: 400;
    color: white;
  }
  h1,
  h2,
  h3,
  h4 {
    height: fit-content;
  }
  h1 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: calc(10px + 2vh);
  }
  h4 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: calc(4px + 2vh);
  }

  h2 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.1rem;
    margin-bottom: calc(7px + 2vh);
  }
  h3 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: var(--blue);
    margin-bottom: calc(5px + 2vh);
  }

  table {
    th {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 1rem;
    }
  }

  span {
    font-weight: 500;
    font-size: 1.15rem;
  }

  b {
    color: var(--blue);
    font-weight: 500;
  }
  div {
    //  height: fit-content;
  }
}
div{
  // embed{
  //   font-size: 0rem !important;
  // }
}
a{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
  color: white;
}


.topbar{
  position: absolute;
  top: 0;
  width: 100%;
}

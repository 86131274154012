@import "./home.module.scss";

.historyTable_section {
  margin: 0;
  width: 100%;
  background-color: var(--colorsec);

  height: inherit;
  position: relative;
  border-radius: 3px;
  height: calc(100%);
  table-layout: fixed;
  width: 100%;
  display: block;

  .historyTable_content {
    margin: 0;
    padding-right: 0px;
    padding-left: 0px;
    height: calc(100% - calc(67px + 1vw));
    display: block;
    overflow: scroll;
    margin-bottom: 4px;
    overflow-x: hidden;

    border-left: 1px solid #505050;
    border-right: 1px solid #505050;
    border-bottom: 1px solid #505050;

    scrollbar-color: rgb(210,210,210) rgb(46,54,69) !important;
    scrollbar-width: thin !important;
  }
  .historyTable_content::-webkit-scrollbar:vertical {
    display: block;
    width: 5px;
    border-radius: 20px;
  }
  .historyTable_content::-webkit-scrollbar:hover {
    display: block;
    width: 7px !important;
    border-radius: 20px;
  }
  .historyTable_content::-webkit-scrollbar-thumb {
    border-right-width: 5px;
    border-radius: 30px;
    background-color: #d9d9d9;
  }
  .historyTable_content::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }

  tr {
    padding-left: 0px !important;
    padding-right: 0px !important;

    td {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

.historyTable_section {
  margin: 0;
  height: 100%;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.historyTable_section_sort {
  background-color: var(--colorsec);
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  border: 1px solid #505050;
  border-radius: 4px 4px 0px 0px;

  font-size: $font9;
  font-family: "worksans";
  font-weight: 500;
  color: black;
  align-items: center;
  height: calc(53px + 1vw);
  padding-right: 5px !important;
  .sort_options {
    width: calc(22px + 0.4vw);

    display: flex;
    align-items: center;
    position: absolute;
    left: 3.4%;
    z-index: 1;
    button {
      border: 1px solid #777777;
      background-color: white;
      width: 100%;
      height: calc(19px + 0.4vw);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 0;
      margin-top: -14px;
      img {
        width: 45%;
      }
    }
  }
  .sort_list_active {
    display: block !important;
  }
  .sort_list {
    display: none;
    background-color: white;
    box-shadow: 3px 5px 7px #d4d4d4;
    li {
      padding-left: 15px;
      padding-top: 7px;
      padding-bottom: 7px;
      cursor: pointer;
    }
    li:hover {
      color: $blue;
      transform: scale(1.01);
    }
    .sort_active {
      color: $blue;
    }
  }
}

.historyTable_padding {
  width: 100%;
  display: flex;
  background-color: #151515;
  align-items: center;
  border-radius: 0px;

  .historyTable_sort {
    text-align: center;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    border-right: 1px solid #505050;

    background-color: var(--toptable);
    height: calc(50px + 1vw);

    font-size: var(--veryverylittlefonttable);
    font-weight: 500;
    color: var(--colortext);
    font-family: "worksans";
    padding-left: 5px;
    padding-right: 5px;
    text-transform: uppercase;

    &IMG {
      margin-left: 10px;
    }
    p {
      color: var(--colortext);
      font-size: var(--veryverylittlefonttable);
      font-weight: 500;
      margin: 0;
      padding: 0;
      font-family: "worksans";
    }
  }

  .historyTable_sort:last-child {
    border-right: 1.5px solid transparent;
  }
  button {
    background-color: var(--colorprinc);
    border-right: 1.5px solid #dee1e6;
    border: none;
    font-family: "worksans";
  }
}

.historyTable_inks:nth-child(2n + 2) {
  background-color: var(--table2);
}
.historyTable_inks_hovered:nth-child(2n + 2) {
  background-color: #414af620;
}
.historyTable_inks {
  margin: 0;
  height: calc(20px + 1.3vw);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--table1);
  font-size: calc(9px + 0.45vw);
  font-family: "worksans";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.95);
  max-height: 4vw;

  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 6px;
  font-family: "worksans";

  &:hover {
    background-color: #414af633;

    font-weight: 400;
    color: black;
    border-bottom: 1px solid #50505000;
    border-right: 1px solid #50505000;
    border-left: 1px solid #50505000;
  }
  &:hover:last-child {
    box-shadow: none !important;
    border: none !important;
  }
  div {
    padding: 0;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  td {
    padding: 0;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--colortext);
    padding-left: 5px;
    padding-right: 5px;
    font-size: var(--veryverylittlefonttable);
    font-family: "worksans";
    p,
    p span {
      padding-left: 5px;
      padding-right: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: var(--veryverylittlefonttable);
      font-family: "worksans";
    }
  }
  .run {
    opacity: 0.4;
  }
}
.historyTable_inks_hovered {
  background-color: #414af620;
}

.historyTable_row {
  border-right: 1px solid #ffffff30;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.historyTable_row:last-child {
  border-right: 0px;
}
